import { ApiManager } from 'utlis/api_manager';
import { FILTER_API_MAP, FILTERS } from 'utlis/constants';

const api = new ApiManager(process.env.REACT_APP_BASE_URL, 1);

export default class FineTuningService {
  static async getDesignReviews(offset = 0, filter = FILTERS.ALL_REVIEWS, pageSize = 12) {
    try {
      const params = {
        limit: pageSize,
        offset,
      };

      let url = 'finetuning/design-reviews';

      if (filter === FILTERS.VIEW_RATED) {
        url = url + '/rated';
      }
      else if (filter === FILTERS.ASSIGNED_TO_ME) {
        url = url + '/assigned-to-me';
      }
      else if (filter !== FILTERS.ALL_REVIEWS) {
        const apiFilter = FILTER_API_MAP[filter] || filter;

        params.template = apiFilter;
      }

      const response = await api.setUrl(url).get(params);

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.message || 'Failed to fetch design reviews');
      }

      throw new Error('An unexpected error occurred');
    }
  }

  static async getDesignReviewJson(designReviewId, jsonPath) {
    try {
      const response = await api
        .setUrl(`finetuning/design-reviews/${designReviewId}/get_json`)
        .post({ json_path: jsonPath });

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.error || 'Failed to fetch JSON data');
      }

      throw new Error('An unexpected error occurred');
    }
  }

  static async getDesignReviewById(designReviewId) {
    try {
      const response = await api.setUrl(`finetuning/design-reviews/${designReviewId}`).get();

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.message || 'Failed to fetch design review');
      }

      throw new Error('An unexpected error occurred');
    }
  }

  static async submitFeedbackRating(designReviewId, feedbackItems) {
    try {
      const payload = {
        rated_feedback: feedbackItems.map(item => ({
          issue: item.issue,
          label: item.label,
          reason: item.reason,
          category: item.category,
          node_ids: item.node_ids,
          detailed_feedback: item.detailed_feedback,
          ratings: {
            accuracy: item.ratings.accuracy,
            relevance: item.ratings.relevance,
            actionable: item.ratings.actionable,
          },
          comment: item.ratings.comment,
          thumbs_up: item.ratings.thumbs_up,
          thumbs_down: item.ratings.thumbs_down,
          desired_feedback: item.ratings.desired_feedback,
        })),
      };

      const response = await api
        .setUrl(`finetuning/design-reviews/${designReviewId}/ratings`)
        .post(payload);

      return response?.data;
    }
    catch (error) {
      if (error.response) {
        throw new Error(error.response.data?.message || 'Failed to submit feedback ratings');
      }

      throw new Error('An unexpected error occurred');
    }
  }

  static async getFigmaReviewerTestUsers() {
    try {
      const response = await api.setUrl('user/figma-reviewer-test-users').get();

      return response?.data || [];
    }
    catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to fetch test users');
    }
  }

  static async assignDesignReview(designReviewId, assignedToUserId) {
    try {
      const payload = {
        design_review: designReviewId,
        assigned_to: assignedToUserId,
      };

      const response = await api.setUrl('finetuning/assign-finetuning').post(payload);

      return response?.data;
    }
    catch (error) {
      throw new Error(error.response?.data?.message || 'Failed to assign design review');
    }
  }
}
