import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import designProLogo from 'assets/images/landing/logo.svg';

import { AccountDropdown } from 'Pages/Home/components/AccountDropdown';

import { ROUTES } from 'routes/route.constants';

import styles from './styles.module.scss';

export const ProjectListingHeader = () => {
  const userProfile = useSelector(state => state.userProfile);

  return (
    <div className={styles['listing-header-wrapper']}>
      <div className={cn('flex space-between align-center', styles['listing-header-container'])}>
        <Link to={ROUTES.LANDING}>
          <img className={styles.logoImage} src={designProLogo} alt="Logo" />
        </Link>
        <div className={cn('flex space-between', styles['nav-items-wrapper'])}>
          <a className={styles['nav-item']} href="mailto:help@designpro.ai">
            Help
          </a>
          <a className={styles['nav-item']} href="mailto:feedback@designpro.ai">
            Share feedback
          </a>
          <AccountDropdown userProfile={userProfile} />
        </div>
      </div>
    </div>
  );
};
