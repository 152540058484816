import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Pagination, Row, Spin } from 'antd'; // Add Spin import
import moment from 'moment';

import designProLogo from 'assets/designProLogo.svg';

import FineTuningService from 'services/fineTuning';

import { FILTERS } from 'utlis/constants';
import { successReactToastify } from 'utlis/toasts';

import { DesignReviewBox } from './components/DesignReviewBox';
import { SingleDesignReview } from './components/SingleDesignReview';
import { SkeletonCard } from './components/SkeletonCard';

import styles from './styles.module.scss';

const PAGE_SIZE = 20; // Matching the API's limit parameter

const FILTER_OPTIONS = Object.values(FILTERS);

export const Finetuning = () => {
  const { designReviewId } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedReview, setSelectedReview] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingSingleReview, setLoadingSingleReview] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(FILTERS.ALL_REVIEWS);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedDesigns, setSelectedDesigns] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem('userObject'));

  const fetchDesignReviews = async (page, filter) => {
    setLoading(true);

    try {
      const offset = (page - 1) * PAGE_SIZE;
      const response = await FineTuningService.getDesignReviews(offset, filter, PAGE_SIZE);

      setTotalItems(response.count);

      return response.results || [];
    }
    catch (apiError) {
      setError(apiError.message);

      return [];
    }
    finally {
      setLoading(false);
    }
  };

  const fetchSingleDesignReview = async id => {
    setLoadingSingleReview(true);

    try {
      const review = await FineTuningService.getDesignReviewById(id);

      setSelectedReview(review);
    }
    catch (singleError) {
      setError(singleError.message);
    }
    finally {
      setLoadingSingleReview(false);
    }
  };

  useEffect(() => {
    if (designReviewId) {
      fetchSingleDesignReview(designReviewId);
    }
    else {
      fetchDesignReviews(currentPage, selectedFilter).then(results => {
        setData(results);
        setFilteredData(results);
      });
    }
  }, [designReviewId, currentPage]);

  const handleFilterChange = async filter => {
    setSelectedFilter(filter);
    setCurrentPage(1); // Reset to first page when filter changes

    const results = await fetchDesignReviews(1, filter);

    setFilteredData(results);
  };

  const handlePageChange = async page => {
    setCurrentPage(page);
    const results = await fetchDesignReviews(page, selectedFilter);

    setFilteredData(results);
    // Scroll to top of the page for better UX
    window.scrollTo(0, 0);
  };

  const handleReviewClick = async review => {
    try {
      setLoadingSingleReview(true);
      const fetchedReview = await FineTuningService.getDesignReviewById(review.uuid);

      setSelectedReview(fetchedReview);
      navigate(`/finetuning/${review.uuid}`);
    }
    catch (err) {
      setError(err.message);
    }
    finally {
      setLoadingSingleReview(false);
    }
  };

  const toggleDesignSelection = designReview => {
    setSelectedDesigns(prevSelected => {
      const existing = prevSelected.find(item => item.uuid === designReview.uuid);

      return existing
        ? prevSelected.filter(item => item.uuid !== designReview.uuid) // Deselect
        : [...prevSelected, designReview]; // Select
    });
  };

  const exportSelectedFeedback = () => {
    if (selectedDesigns.length === 0) {
      return;
    }

    const exportData = {
      total_designs_exported: selectedDesigns.length,
      designs: selectedDesigns.map(design => ({
        design_uuid: design.uuid,
        review_frame_name: design.extra_data?.review_frame_name || 'N/A',
        template: design.extra_data?.template || 'N/A',
        created_at: design.created_at ? moment(design.created_at).format('DD MMM YYYY, HH:mm A') : 'N/A',
        page_type: design.extra_data?.page_type || 'N/A',
        owner: design.owner
          ? {
            uuid: design.owner.uuid,
            email: design.owner.email,
            full_name: design.owner.full_name || 'N/A',
          }
          : null,
        assigned_users: design.assigned_users
          ? design.assigned_users.map(user => ({
            uuid: user.uuid,
            email: user.email,
            full_name: user.full_name || 'N/A',
          }))
          : [],
        labeled_by: design.reviewers.map(reviewer => ({
          username: reviewer.username,
          full_name: reviewer.full_name,
          email: reviewer.email,
        })),
        feedbacks: (design.ratings && design.ratings.length > 0)
          ? design.ratings.map(rating => ({
            reviewer_id: rating.user_uuid,
            reviewer_name: design.reviewers.find(r => r.uuid === rating.user_uuid)?.full_name || 'Unknown',
            total_accuracy_score: rating.total_accuracy_score,
            total_actionable_score: rating.total_actionable_score,
            total_relevance_score: rating.total_relevance_score,
            feedback_count: rating.feedback_count,
            rated_feedback: rating.rated_feedback,
          }))
          : design.final_feedback || [],
      })),
    };

    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const filename = `Exported_Feedbacks_${new Date().toISOString()}.json`;

    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    successReactToastify(`Exported feedback for ${selectedDesigns.length} designs.`, { autoClose: 3000 });
  };

  if (loadingSingleReview && selectedReview) {
    return (
      <div
        style={{
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" tip="Loading review..." />
      </div>
    );
  }

  if (selectedReview) {
    return (
      <SingleDesignReview
        designReview={selectedReview}
        onBack={() => setSelectedReview(null)}
        currentUser={currentUser}
        setSelectedReview={setSelectedReview}
      />
    );
  }

  return (
    <div className={styles['home-wrapper']}>
      <div className={styles['dashboard-header']}>
        <img className={styles['header-logo']} src={designProLogo} alt="design pro logo" />
        <h1>
          Dashboard
        </h1>
        <div className={styles.actionButtons}>
          {selectedDesigns.length > 0 && (
            <button
              type="primary"
              className={styles.unselectButton}
              onClick={() => setSelectedDesigns([])}
            >
              Unselect
            </button>
          )}

          <button
            type="primary"
            className={styles.exportButton}
            disabled={selectedDesigns.length === 0} // Disabled when no design is selected
            onClick={exportSelectedFeedback}
          >
            Export Feedback (
            {selectedDesigns.length}
            )
          </button>
        </div>
      </div>

      <div className={styles['filter-capsules']}>
        {FILTER_OPTIONS.map(filter => (
          <Button
            key={filter}
            type={selectedFilter === filter ? 'primary' : 'default'}
            onClick={() => handleFilterChange(filter)}
            className={styles['filter-button']}
          >
            {filter}
          </Button>
        ))}
      </div>

      {loading
        ? <SkeletonCard rows={PAGE_SIZE} />
        : error
          ? (
            <div>
              Error:
              {' '}
              {error}
            </div>
          )
          : (
            <>
              <DesignReviewBox
                data={filteredData}
                currentUser={currentUser}
                selectedDesigns={selectedDesigns}
                toggleSelection={toggleDesignSelection}
                handleReviewClick={handleReviewClick}
              />

              <Row justify="center" className={styles['pagination-wrapper']}>
                <Pagination
                  current={currentPage}
                  total={totalItems}
                  pageSize={PAGE_SIZE}
                  onChange={handlePageChange}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  showSizeChanger={false}
                  hideOnSinglePage
                />
              </Row>
            </>
          )}
    </div>
  );
};
