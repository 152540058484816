import styles from './styles.module.scss';

const FramerLanding = () => (
  <div className={styles.container}>
    <iframe
      src={process.env.REACT_APP_FRAMER_WEBSITE_URL}
      title="DesignPro Landing Page"
      className={styles.iframe}
    />
  </div>
);

export default FramerLanding;
