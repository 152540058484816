import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CheckSquareFilled, UserOutlined } from '@ant-design/icons';
import { faInfoCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumb, Input, Slider, Tooltip } from 'antd';
import moment from 'moment';
import cn from 'classnames';

import { ROUTES } from 'routes/route.constants';

import FineTuningService from 'services/fineTuning';

import { errorReactToastify, successReactToastify } from 'utlis/toasts';

import styles from './styles.module.scss';

const FIREBASE_PREFIX = process.env.REACT_APP_FIREBASE_STORAGE_PREFIX;
const MIN_MARKER_SIZE = 10; // minimum marker radius in pixels
const MAX_MARKER_SIZE = 25; // maximum marker radius in pixels

const RATING_VALUE = {
  'MIN': 1,
  'MAX': 5,
};

const PREDEFINED_LABELS = [
  'Layout',
  'CTA',
  'Hierarchy',
  'Typography',
  'Color',
  'Accessibility',
];

export const SingleDesignReview = ({ designReview, onBack, currentUser, setSelectedReview }) => {
  const getEmptyFeedback = () => designReview.final_feedback
    .filter(item => item.category !== 'positive')
    .map(item => ({
      ...item,
      ratings: {
        accuracy: 0,
        relevance: 0,
        actionable: 0,
        comment: '',
        thumbs_up: false,
        thumbs_down: false,
        desired_feedback: {
          detailed_feedback: '',
          reason: '',
        },
      },
    }));

  const [feedbackItems, setFeedbackItems] = useState(getEmptyFeedback);

  const [figmaData, setFigmaData] = useState({});
  const [positionsMap, setPositionsMap] = useState({});
  const canvasRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [isPanning, setIsPanning] = useState(false);
  const [lastMouseX, setLastMouseX] = useState(0);
  const [lastMouseY, setLastMouseY] = useState(0);
  const [image, setImage] = useState(null);
  const [activeMarkers, setActiveMarkers] = useState([]);
  const [baseScale, setBaseScale] = useState(1);
  const bufferCanvasRef = useRef(null);
  const rafRef = useRef(null);
  const containerRef = useRef(null);
  const [canReset, setCanReset] = useState(false);
  const isReviewer = designReview.reviewers.some(reviewer => reviewer.uuid === currentUser?.uuid) || false;
  const [isAddingFeedback, setIsAddingFeedback] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newFeedback, setNewFeedback] = useState({
    label: '',
    category: 'error', // default value
    detailed_feedback: '',
    reason: '',
    node_ids: [],
    selectedPoint: null,
  });
  const [isOwnReview, setIsOwnReview] = useState(true);
  const [selectedReviewer, setSelectedReviewer] = useState(null);

  const calculateRelativePositions = (node, originX, originY, relativePositionsMap = {}) => {
    const { x, y, width, height } = node.absoluteBoundingBox || {};
    const relativeX = x - originX;
    const relativeY = y - originY;

    relativePositionsMap[node.id] = { x: relativeX, y: relativeY, width, height };

    if (node.children && node.children.length > 0) {
      node.children.forEach(child => calculateRelativePositions(child, originX, originY, relativePositionsMap));
    }

    return relativePositionsMap;
  };

  useEffect(() => {
    const userReview = designReview.ratings.find(rating => rating.user_uuid === currentUser?.uuid);

    if (userReview) {
      const formattedFeedback = userReview.rated_feedback.map(item => ({
        ...item,
        ratings: {
          accuracy: item.ratings.accuracy || 0,
          relevance: item.ratings.relevance || 0,
          actionable: item.ratings.actionable || 0,
          comment: item.comment || '',
          thumbs_up: item.thumbs_up || false,
          thumbs_down: item.thumbs_down || false,
          desired_feedback: {
            detailed_feedback: item.desired_feedback?.detailed_feedback || '',
            reason: item.desired_feedback?.reason || '',
          },
        },
      }));

      setSelectedReviewer(currentUser.uuid);
      setFeedbackItems(formattedFeedback);
    }
    else {
      setSelectedReviewer(null);
    }
  }, [designReview, currentUser]);

  useEffect(() => {
    const fetchFigmaData = async () => {
      try {
        const figma_data = await FineTuningService.getDesignReviewJson(
          designReview.uuid,
          designReview.extra_data.json_path
        );

        setFigmaData(figmaData);

        const originX = figma_data.absoluteBoundingBox.x;
        const originY = figma_data.absoluteBoundingBox.y;
        const calculatedPositions = calculateRelativePositions(figma_data, originX, originY);

        setPositionsMap(calculatedPositions);
      }
      catch (error) {
        errorReactToastify('Error in fetching figma data');
      }
    };

    fetchFigmaData();
  }, [designReview]);

  const loadReviewerData = () => {
    if (selectedReviewer === 'new_feedback') {
      setFeedbackItems(getEmptyFeedback());
      setIsOwnReview(true);
      setSelectedReviewer(null);

      return;
    }

    // Find the rating for the selected reviewer
    const reviewerRating = designReview.ratings.find(rating => rating.user_uuid === selectedReviewer);

    if (!reviewerRating) {
      errorReactToastify('No ratings found for this user.');

      return;
    }

    const populatedFeedbackItems = reviewerRating.rated_feedback.map(item => ({
      label: item.label,
      reason: item.reason,
      category: item.category,
      node_ids: item.node_ids,
      detailed_feedback: item.detailed_feedback,
      ratings: {
        accuracy: item.ratings.accuracy || 0,
        relevance: item.ratings.relevance || 0,
        actionable: item.ratings.actionable || 0,
        comment: item.comment || '',
        thumbs_up: item.thumbs_up || false,
        thumbs_down: item.thumbs_down || false,
        desired_feedback: {
          detailed_feedback: item.desired_feedback?.detailed_feedback || '',
          reason: item.desired_feedback?.reason || '',
        },
      },
    }));

    setIsOwnReview(selectedReviewer === currentUser.uuid);
    setFeedbackItems(populatedFeedbackItems);
    successReactToastify('Feedback loaded');
  };

  const exportReviewerData = () => {
    const reviewerRating = designReview.ratings.find(rating => rating.user_uuid === selectedReviewer);

    if (!reviewerRating) {
      errorReactToastify('No rating data found for the selected reviewer.');

      return;
    }

    const exportData = {
      design_uuid: designReview.uuid,
      frame_name: designReview.extra_data?.review_frame_name || 'N/A',
      template: designReview.extra_data?.template || 'N/A',
      created_at: designReview.created_at
        ? moment(designReview.created_at).format('DD MMM YYYY, HH:mm A')
        : 'N/A',
      page_type: designReview.extra_data?.page_type || 'N/A',
      owner: designReview.owner
        ? {
          uuid: designReview.owner.uuid,
          email: designReview.owner.email,
          full_name: designReview.owner.full_name || 'N/A',
        }
        : null,
      assigned_users: designReview.assigned_users
        ? designReview.assigned_users.map(user => ({
          uuid: user.uuid,
          email: user.email,
          full_name: user.full_name || 'N/A',
        }))
        : [],
      exported_reviewer_id: selectedReviewer,
      exported_reviewer_fullname: designReview.reviewers.find(r => r.uuid === selectedReviewer)?.full_name || 'unknown',
      ratings: reviewerRating.rated_feedback,
    };

    const jsonString = JSON.stringify(exportData, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const filename = `${exportData.exported_reviewer_fullname}'s_Feedback_${designReview.uuid}.json`;

    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

    successReactToastify(`Exported ${exportData.exported_reviewer_fullname}'s feedback.`, { autoClose: 3000 });
  };

  // Function to handle thumbs up and updating the feedback data
  const handleThumbsUp = index => {
    setFeedbackItems(
      prevItems => prevItems.map(
        (item, i) => i === index
          ? {
            ...item,
            ratings: {
              ...item.ratings,
              thumbs_up: true,
              thumbs_down: false,
            },
          }
          : item
      )
    );
  };

  const handleThumbsDown = index => {
    setFeedbackItems(
      prevItems => prevItems.map(
        (item, i) => i === index
          ? {
            ...item,
            ratings: {
              ...item.ratings,
              thumbs_down: true,
              thumbs_up: false,
              desired_feedback: {
                detailed_feedback: '',
                reason: '',
              },
            },
          }
          : item
      )
    );
  };

  // Function to handle additional feedback and comments
  const handleFeedbackChange = (index, value) => {
    setFeedbackItems(
      prevItems => prevItems.map(
        (item, i) => i === index
          ? {
            ...item,
            ratings: {
              ...item.ratings,
              comment: value,
            },
          }
          : item
      )
    );
  };

  // Add a new function to handle metrics ratings
  const handleMetricsRating = (index, metricType, value) => {
    setFeedbackItems(
      prevItems => prevItems.map(
        (item, i) => i === index
          ? {
            ...item,
            ratings: {
              ...item.ratings,
              [metricType]: value,
            },
          }
          : item
      )
    );
  };

  // Handler for desired feedback
  const handleDesiredFeedbackChange = (index, field, value) => {
    setFeedbackItems(
      prevItems => prevItems.map(
        (item, i) => i === index
          ? {
            ...item,
            ratings: {
              ...item.ratings,
              desired_feedback: {
                ...item.ratings.desired_feedback,
                [field]: value,
              },
            },
          }
          : item
      )
    );
  };

  // Calculate average scores for accuracy, relevance, and actionability
  const averageScores = useMemo(() => {
    if (feedbackItems.length === 0) {
      return { accuracy: 0, relevance: 0, actionable: 0 };
    }

    return feedbackItems.reduce(
      (acc, item) => {
        acc.accuracy += item.ratings.accuracy || 0;
        acc.relevance += item.ratings.relevance || 0;
        acc.actionable += item.ratings.actionable || 0;

        return acc;
      },
      { accuracy: 0, relevance: 0, actionable: 0 }
    );
  }, [feedbackItems]);

  const totalFeedbacks = feedbackItems.length;
  const averageAccuracy = (averageScores.accuracy / totalFeedbacks).toFixed(1);
  const averageRelevance = (averageScores.relevance / totalFeedbacks).toFixed(1);
  const averageActionable = (averageScores.actionable / totalFeedbacks).toFixed(1);

  const drawCanvas = () => {
    if (!canvasRef.current || !image) {
      return;
    }

    // Cancel any pending animation frame
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }

    rafRef.current = requestAnimationFrame(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');

      // Create buffer canvas if it doesn't exist
      if (!bufferCanvasRef.current) {
        bufferCanvasRef.current = document.createElement('canvas');
        bufferCanvasRef.current.width = canvas.width;
        bufferCanvasRef.current.height = canvas.height;
      }

      const buffer = bufferCanvasRef.current;
      const bufferCtx = buffer.getContext('2d');

      // Clear both canvases
      bufferCtx.clearRect(0, 0, buffer.width, buffer.height);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw to buffer first
      bufferCtx.save();
      bufferCtx.translate(offsetX, offsetY);
      bufferCtx.scale(scale * baseScale, scale * baseScale);
      bufferCtx.drawImage(image, 0, 0, image.width, image.height);
      bufferCtx.restore();

      // Draw markers on buffer with animation
      if (activeMarkers.length > 0) {
        const time = performance.now() / 1000; // Get current time in seconds

        activeMarkers.forEach(nodeId => {
          const position = positionsMap[nodeId];

          if (position) {
            const { x, y } = position;
            const markerX = x * scale * baseScale + offsetX;
            const markerY = y * scale * baseScale + offsetY;
            // Calculate base radius with limits
            const baseRadius = Math.min(
              Math.max(15 / scale, MIN_MARKER_SIZE / scale),
              MAX_MARKER_SIZE / scale
            );

            // Pulse animation
            const pulseScale = 1 + 0.2 * Math.sin(time * 3); // Pulsing effect
            const radius = baseRadius * pulseScale;

            bufferCtx.save();

            // Outer glow effect
            bufferCtx.beginPath();
            bufferCtx.arc(markerX, markerY, radius + 5 / scale, 0, 2 * Math.PI);
            bufferCtx.fillStyle = 'rgba(255, 71, 71, 0.2)';
            bufferCtx.fill();

            // Main marker circle
            bufferCtx.beginPath();
            bufferCtx.arc(markerX, markerY, radius, 0, 2 * Math.PI);
            bufferCtx.fillStyle = 'rgba(255, 71, 71, 0.8)';
            bufferCtx.fill();

            // White border
            bufferCtx.strokeStyle = 'white';
            bufferCtx.lineWidth = 3 / scale;
            bufferCtx.stroke();

            // Inner dot
            bufferCtx.beginPath();
            bufferCtx.arc(markerX, markerY, baseRadius * 0.4, 0, 2 * Math.PI);
            bufferCtx.fillStyle = 'white';
            bufferCtx.fill();

            bufferCtx.restore();
          }
        });
      }

      // Copy buffer to main canvas
      ctx.drawImage(buffer, 0, 0);

      // Request next frame for animation
      if (activeMarkers.length > 0) {
        rafRef.current = requestAnimationFrame(() => drawCanvas());
      }
    });
  };

  const resetZoom = () => {
    if (!canvasRef.current || !image) {
      return;
    }

    const canvas = canvasRef.current;
    const scaleY = canvas.height / image.height;
    const newBaseScale = scaleY;

    setScale(1);
    setBaseScale(newBaseScale);

    // Center the image
    const scaledWidth = image.width * newBaseScale;
    const scaledHeight = image.height * newBaseScale;
    const centerOffsetX = (canvas.width - scaledWidth) / 2;
    const centerOffsetY = (canvas.height - scaledHeight) / 2;

    setOffsetX(centerOffsetX);
    setOffsetY(centerOffsetY);
  };

  const showOnCanvas = nodeIds => {
    // Reset zoom first
    resetZoom();
    // Then show markers
    setActiveMarkers(nodeIds);
    drawCanvas();
  };

  const handleMouseDown = e => {
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setIsPanning(true);
    setLastMouseX(x);
    setLastMouseY(y);
  };

  const handleMouseMove = e => {
    if (!isPanning) {
      return;
    }

    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const dx = x - lastMouseX;
    const dy = y - lastMouseY;

    setOffsetX(prevX => prevX + dx);
    setOffsetY(prevY => prevY + dy);

    setLastMouseX(x);
    setLastMouseY(y);
  };

  const handleMouseUp = () => {
    setIsPanning(false);
  };

  const handleWheel = e => {
    e.preventDefault();

    const rect = canvasRef.current.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    // Calculate zoom
    const zoom = e.deltaY < 0 ? 1.1 : 0.9;
    const newScale = Math.min(Math.max(scale * zoom, 0.1), 5);

    // Calculate new offsets to zoom towards mouse position
    const scaleChange = newScale / scale;
    const newOffsetX = mouseX - (mouseX - offsetX) * scaleChange;
    const newOffsetY = mouseY - (mouseY - offsetY) * scaleChange;

    setScale(newScale);
    setOffsetX(newOffsetX);
    setOffsetY(newOffsetY);
  };

  // Function to check if image is at original state
  const isAtOriginalState = () => {
    if (!canvasRef.current || !image) {
      return true;
    }

    const canvas = canvasRef.current;
    const originalScale = canvas.width / image.width;
    const centerX = (canvas.width - image.width * originalScale) / 2;
    const centerY = (canvas.height - image.height * originalScale) / 2;

    // Allow for small floating point differences
    const isOriginalScale = Math.abs(scale - 1) < 0.001;
    const isOriginalPosition = Math.abs(offsetX - centerX) < 1 && Math.abs(offsetY - centerY) < 1;

    return isOriginalScale && isOriginalPosition;
  };

  // Update effect to check reset availability whenever transform changes
  useEffect(() => {
    setCanReset(!isAtOriginalState());
  }, [scale, offsetX, offsetY]);

  useEffect(() => {
    drawCanvas();
  }, [figmaData, scale, offsetX, offsetY]);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    canvas.addEventListener('wheel', handleWheel, { passive: false });

    return () => {
      canvas.removeEventListener('wheel', handleWheel);
    };
  }, [scale, offsetX, offsetY]);

  useEffect(() => {
    if (!designReview) {
      return;
    }

    const img = new Image();

    img.src = FIREBASE_PREFIX + encodeURIComponent(designReview.extra_data.frame_image) + '?alt=media';
    img.onload = () => {
      setImage(img);
    };
  }, [designReview]);

  useEffect(() => {
    drawCanvas();
  }, [image, scale, offsetX, offsetY, activeMarkers, positionsMap]);

  useEffect(() => {
    if (!image || !canvasRef.current) {
      return;
    }

    const canvas = canvasRef.current;
    const initialScale = canvas.width / image.width;

    setBaseScale(initialScale);

    // Center the image initially
    const centerOffsetX = (canvas.width - image.width * initialScale) / 2;
    const centerOffsetY = (canvas.height - image.height * initialScale) / 2;

    setOffsetX(centerOffsetX);
    setOffsetY(centerOffsetY);
  }, [image]);

  // Clean up RAF on unmount
  useEffect(() => () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }
  }, []);

  // Add resize handler
  const handleResize = () => {
    if (!containerRef.current || !canvasRef.current || !image) {
      return;
    }

    const container = containerRef.current;
    const canvas = canvasRef.current;

    canvas.width = container.clientWidth;
    canvas.height = container.clientHeight;

    const scaleY = canvas.height / image.height;
    const newBaseScale = scaleY;

    setBaseScale(newBaseScale);
    setScale(1);

    // Center the image
    const scaledWidth = image.width * newBaseScale;
    const scaledHeight = image.height * newBaseScale;
    const centerOffsetX = (canvas.width - scaledWidth) / 2;
    const centerOffsetY = (canvas.height - scaledHeight) / 2;

    setOffsetX(centerOffsetX);
    setOffsetY(centerOffsetY);

    if (bufferCanvasRef.current) {
      bufferCanvasRef.current.width = canvas.width;
      bufferCanvasRef.current.height = canvas.height;
    }

    drawCanvas();
  };

  // Add resize effect
  useEffect(() => {
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [image]);

  // Function to handle feedback submission
  const onSubmitFeedback = async () => {
    try {
      const designReviewId = designReview.uuid;

      await FineTuningService.submitFeedbackRating(designReviewId, feedbackItems);
      const fetchedReview = await FineTuningService.getDesignReviewById(designReviewId);

      setSelectedReview(fetchedReview);
      successReactToastify('Feedback submitted successfully', { autoClose: 3000 });
    }
    catch (error) {
      errorReactToastify('Failed to submit feedback. Please try again.');
    }
  };

  const handleCanvasClick = e => {
    if (!isAddingFeedback) {
      return;
    }

    const rect = canvasRef.current.getBoundingClientRect();
    // Calculate click position relative to the transformed canvas
    const viewX = e.clientX - rect.left;
    const viewY = e.clientY - rect.top;

    // Transform back to image coordinates
    const mouseX = (viewX - offsetX) / (scale * baseScale);
    const mouseY = (viewY - offsetY) / (scale * baseScale);

    // Check if click is within image bounds
    if (mouseX < 0 || mouseX > image.width || mouseY < 0 || mouseY > image.height) {
      errorReactToastify('Please select a point on the image', { autoClose: 5000 });

      return;
    }

    // Find nodes that contain this point, starting from the smallest ones
    const matchingNodes = Object.entries(positionsMap)
      .filter(
        ([, pos]) => mouseX >= pos.x
        && mouseX <= pos.x + pos.width
        && mouseY >= pos.y
        && mouseY <= pos.y + pos.height
      )
      // Sort by area (ascending) to prefer smaller, more specific nodes
      .sort(([, a], [, b]) => (a.width * a.height) - (b.width * b.height));

    // Take the smallest node that contains the point
    const selectedNodes = matchingNodes.length > 0 ? [matchingNodes[0][0]] : [];

    setNewFeedback(prev => ({
      ...prev,
      node_ids: selectedNodes,
      selectedPoint: { x: mouseX, y: mouseY },
    }));

    // If we found a node, show the modal
    if (selectedNodes.length > 0) {
      setShowModal(true);
      setIsAddingFeedback(false);
    }
    else {
      errorReactToastify('No design element found at this position', { autoClose: 3000 });
    }
  };

  const handleSubmitNewFeedback = () => {
    if (!newFeedback.label || !newFeedback.detailed_feedback || !newFeedback.reason) {
      errorReactToastify('Please fill all required fields');

      return;
    }

    const newFeedbackItem = {
      ...newFeedback,
      detailed_feedback: [newFeedback.detailed_feedback], // Match existing format
      human_generated: true, // Add this flag
      ratings: {
        accuracy: 0,
        relevance: 0,
        actionable: 0,
        comment: '',
        thumbs_up: false,
        thumbs_down: false,
        desired_feedback: {
          detailed_feedback: '',
          reason: '',
        },
      },
    };

    setFeedbackItems(prev => [...prev, newFeedbackItem]);
    setShowModal(false);
    setNewFeedback({
      label: '',
      category: 'error',
      detailed_feedback: '',
      reason: '',
      node_ids: [],
      selectedPoint: null,
    });
  };

  return (
    <div className={styles['container']}>
      <div className={styles['top-nav']}>
        <div className={styles['nav-left']}>
          <Breadcrumb className={styles['breadcrumb']}>
            <Breadcrumb.Item>
              <Link to={ROUTES.FINETUNING} onClick={onBack}>
                Finetuning Dashboard
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {`${designReview.extra_data.page_name} (${designReview.extra_data.review_frame_name})`}
            </Breadcrumb.Item>
          </Breadcrumb>
          {designReview.reviewers.length > 0 && (
            <div className={styles['reviewer-controls']}>
              <label className={styles['reviewer-label']}>
                Reviewed by:
              </label>
              <select
                className={styles['reviewer-dropdown']}
                value={selectedReviewer || ''}
                onChange={e => setSelectedReviewer(e.target.value)}
              >
                <option value="" disabled>
                  Select a reviewer
                </option>
                {selectedReviewer && !isReviewer && (
                  <option value="new_feedback">
                    Submit Your Feedback
                  </option>
                )}
                {designReview.reviewers.map(reviewer => (
                  <option key={reviewer.uuid} value={reviewer.uuid}>
                    {reviewer.full_name}
                  </option>
                ))}
              </select>

              <button
                onClick={() => loadReviewerData()}
                className={styles['reviewer-button']}
                disabled={!selectedReviewer}
              >
                Load Data
              </button>

              <button
                className={styles['export-button']}
                onClick={exportReviewerData}
                disabled={!selectedReviewer}
              >
                Export
              </button>

            </div>
          )}
        </div>

        {isReviewer && (
          <span className={styles['reviewed-message']}>
            You've already reviewed this design
            <CheckSquareFilled style={{ color: 'green', marginLeft: '5px' }} />
          </span>
        )}
      </div>

      <div className={styles['columns']}>
        <div className={styles['canvas-column']} ref={containerRef}>
          <div className={styles['canvas-header']}>
            <h1>
              Frame Image
            </h1>
            <div>
              <button
                onClick={resetZoom}
                disabled={!canReset}
                className={styles['reset-button']}
              >
                Reset View
              </button>
              <button
                onClick={() => setIsAddingFeedback(true)}
                className={cn(styles['reset-button'], { [styles.active]: isAddingFeedback })}
              >
                Add New Feedback
              </button>
            </div>
          </div>
          <div className={styles['canvas-wrapper']}>
            <canvas
              ref={canvasRef}
              onMouseDown={e => {
                if (isAddingFeedback) {
                  handleCanvasClick(e);
                }
                else {
                  handleMouseDown(e);
                }
              }}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              className={cn(styles.canvas, { [styles['adding-feedback']]: isAddingFeedback })}
              style={{ cursor: isAddingFeedback ? 'crosshair' : 'grab' }}
            />
          </div>
        </div>

        <div className={styles['feedback-column']}>
          <h1>
            Feedback List
            {' '}
            {designReview.extra_data.template}
          </h1>

          <ul className={styles['feedback-list']}>
            {feedbackItems.map((item, index) => (
              <li
                key={index}
                className={cn(
                  styles['feedback-item'],
                  {
                    [styles['feedback-item-error']]: item.category === 'error',
                    [styles['feedback-item-suggestion']]: item.category === 'suggestion',
                  }
                )}
              >
                <div
                  className={cn(
                    styles.header,
                    {
                      [styles['feedback-item-error']]: item.category === 'error',
                      [styles['feedback-item-suggestion']]: item.category === 'suggestion',
                    }
                  )}
                >
                  <div className={styles['header-left']}>
                    <span className={styles['label']}>
                      {item.label}
                    </span>
                    {item.human_generated && (
                      <Tooltip title="Human Generated Feedback">
                        <span className={styles['human-tag']}>
                          <UserOutlined />
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <span className={styles['category']}>
                    {item.category}
                  </span>
                </div>

                <div className={styles['content']}>
                  <p>
                    <strong>
                      Detailed Feedback:
                    </strong>
                    &nbsp;
                    {item.detailed_feedback[0]}
                  </p>
                  <p>
                    <strong>
                      Reason:
                    </strong>
                    &nbsp;
                    {item.reason}
                  </p>

                  <button
                    className={styles['show-image-button']}
                    onClick={() => {
                      showOnCanvas(item.node_ids);
                    }}
                  >
                    Show on Image
                  </button>

                  <div className={styles['scores']}>
                    <div className={styles['score']}>
                      <label>
                        Accuracy
                      </label>
                      <Tooltip className={styles['tooltip']} title="How accurate is this AI-generated feedback?">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Tooltip>
                      <Slider
                        min={RATING_VALUE.MIN}
                        max={RATING_VALUE.MAX}
                        value={item.ratings.accuracy}
                        onChange={value => handleMetricsRating(index, 'accuracy', value)}
                      />
                    </div>
                    <div className={styles['score']}>
                      <label>
                        Relevancy
                      </label>
                      <Tooltip className={styles['tooltip']} title="How relevant is the feedback to the design item?">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Tooltip>
                      <Slider
                        min={RATING_VALUE.MIN}
                        max={RATING_VALUE.MAX}
                        value={item.ratings.relevance}
                        onChange={value => handleMetricsRating(index, 'relevance', value)}
                      />
                    </div>
                    <div className={styles['score']}>
                      <label>
                        Actionability
                      </label>
                      <Tooltip className={styles['tooltip']} title="Is the feedback actionable?">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Tooltip>
                      <Slider
                        min={RATING_VALUE.MIN}
                        max={RATING_VALUE.MAX}
                        value={item.ratings.actionable}
                        onChange={value => handleMetricsRating(index, 'actionable', value)}
                      />
                    </div>
                  </div>
                  <div className={styles['feedback-footer']}>
                    <button
                      className={cn(styles['thumbs-emoji'], { [styles.active]: item.ratings.thumbs_up })}
                      onClick={() => handleThumbsUp(index)}
                    >
                      <FontAwesomeIcon icon={faThumbsUp} color={item.ratings.thumbs_up ? 'black' : 'lightgrey'} />
                    </button>
                    <button
                      className={cn(styles['thumbs-emoji'], { [styles.active]: item.ratings.thumbs_down })}
                      onClick={() => handleThumbsDown(index)}
                    >
                      <FontAwesomeIcon icon={faThumbsDown} color={item.ratings.thumbs_down ? 'black' : 'lightgrey'} />
                    </button>
                    <Input.TextArea
                      placeholder="Add a comment"
                      autoSize
                      value={item.ratings.comment}
                      onChange={e => handleFeedbackChange(index, e.target.value)}
                    />
                  </div>
                  {item.ratings.thumbs_down && (
                    <div className={styles['desired-feedback']}>
                      <h4>
                        Desired Feedback
                      </h4>
                      <Input.TextArea
                        placeholder="What feedback would you give?"
                        autoSize
                        value={item.ratings.desired_feedback.detailed_feedback}
                        onChange={e => handleDesiredFeedbackChange(index, 'detailed_feedback', e.target.value)}
                        className={styles['desired-input']}
                      />
                      <Input.TextArea
                        placeholder="What is the reason for your feedback?"
                        autoSize
                        value={item.ratings.desired_feedback.reason}
                        onChange={e => handleDesiredFeedbackChange(index, 'reason', e.target.value)}
                        className={styles['desired-input']}
                      />
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>

          <div className={styles['average-scores']}>
            <h3>
              Average Scores
            </h3>
            <p>
              Accuracy:&nbsp;
              <b>
                {(averageAccuracy / RATING_VALUE.MAX * 100).toFixed(2)}
                %
              </b>
            </p>
            <p>
              Relevance:&nbsp;
              <b>
                {(averageRelevance / RATING_VALUE.MAX * 100).toFixed(2)}
                %
              </b>
            </p>
            <p>
              Actionability:&nbsp;
              <b>
                {(averageActionable / RATING_VALUE.MAX * 100).toFixed(2)}
                %
              </b>
            </p>

            {isOwnReview && (
              <button
                className={styles['submit-button']}
                onClick={() => {
                  onSubmitFeedback();
                }}
              >
                {isReviewer ? 'Update' : 'Submit'}
              </button>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <div className={styles['modal-overlay']}>
          <div className={styles['modal']}>
            <h2>
              Add New Feedback
            </h2>
            <div className={styles['modal-content']}>
              <div className={styles['input-group']}>
                <label>
                  Label:
                </label>
                <div className={styles['label-suggestions']}>
                  {PREDEFINED_LABELS.map(label => (
                    <button
                      key={label}
                      className={cn(styles['label-suggestion'], { [styles.selected]: newFeedback.label === label })}
                      onClick={() => setNewFeedback(prev => ({ ...prev, label }))}
                    >
                      {label}
                    </button>
                  ))}
                </div>
                <input
                  type="text"
                  value={newFeedback.label}
                  onChange={e => setNewFeedback(prev => ({ ...prev, label: e.target.value }))}
                  placeholder="Enter custom label"
                />
              </div>

              <div className={styles['input-group']}>
                <label>
                  Category:
                </label>
                <select
                  value={newFeedback.category}
                  onChange={e => setNewFeedback(prev => ({ ...prev, category: e.target.value }))}
                >
                  <option value="error">
                    Error
                  </option>
                  <option value="suggestion">
                    Suggestion
                  </option>
                </select>
              </div>

              <div className={styles['input-group']}>
                <label>
                  Detailed Feedback:
                </label>
                <textarea
                  value={newFeedback.detailed_feedback}
                  onChange={e => setNewFeedback(prev => ({ ...prev, detailed_feedback: e.target.value }))}
                  placeholder="Enter detailed feedback"
                />
              </div>

              <div className={styles['input-group']}>
                <label>
                  Reason:
                </label>
                <textarea
                  value={newFeedback.reason}
                  onChange={e => setNewFeedback(prev => ({ ...prev, reason: e.target.value }))}
                  placeholder="Enter reason"
                />
              </div>

              <div className={styles['modal-buttons']}>
                <button onClick={handleSubmitNewFeedback}>
                  Submit
                </button>
                <button onClick={() => setShowModal(false)}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SingleDesignReview.propTypes = {
  designReview: PropTypes.shape({
    final_feedback: PropTypes.array.isRequired,
    uuid: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    extra_data: PropTypes.shape({
      json_path: PropTypes.string.isRequired,
      frame_image: PropTypes.string.isRequired,
      page_name: PropTypes.string.isRequired,
      review_frame_name: PropTypes.string.isRequired,
      template: PropTypes.string.isRequired,
      page_type: PropTypes.string.isRequired,
    }).isRequired,
    owner: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      full_name: PropTypes.string,
    }).isRequired,
    ratings: PropTypes.arrayOf(PropTypes.shape({
      rated_feedback: PropTypes.oneOfType([
        PropTypes.shape({
          ratings: PropTypes.shape({
            accuracy: PropTypes.number,
            relevance: PropTypes.number,
            actionable: PropTypes.number,
          }),
          comment: PropTypes.string,
          thumbs_up: PropTypes.bool,
          thumbs_down: PropTypes.bool,
          desired_feedback: PropTypes.shape({
            detailed_feedback: PropTypes.string,
            reason: PropTypes.string,
          }),
        }),
        PropTypes.array,
        PropTypes.oneOf([null]),
      ]),
    })).isRequired,
    assigned_users: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        full_name: PropTypes.string,
      })
    ),
    reviewers: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.string,
      email: PropTypes.string,
      username: PropTypes.string,
    })).isRequired,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    uuid: PropTypes.string,
  }).isRequired,
  setSelectedReview: PropTypes.func.isRequired,
};
