import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FileImageOutlined, PlusCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Checkbox, Dropdown, Menu, Table, Tooltip } from 'antd';
import moment from 'moment';

import FineTuningService from 'services/fineTuning';

import styles from './styles.module.scss';

export const DesignReviewBox = ({
  data,
  selectedDesigns,
  toggleSelection,
  handleReviewClick,
}) => {
  const [dropdownOpenRow, setDropdownOpenRow] = useState(null);
  const [finetuningUsers, setFinetuningUsers] = useState([]);
  const [assignedUsersMap, setAssignedUsersMap] = useState({});

  const tableData = useMemo(
    () => data.map(item => ({
      uuid: item.uuid,
      frame_image: item.extra_data?.frame_image || null,
      page_type: item.extra_data?.page_type || 'N/A',
      template: item.extra_data?.template || 'No template available',
      review_frame_name: item.extra_data?.review_frame_name || 'N/A',
      created_at: item.created_at,
      reviewers: item.reviewers,
      assigned_users: item.assigned_users || [],
      owner_email: item.owner?.email || 'N/A',
    })),
    [data]
  );

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await FineTuningService.getFigmaReviewerTestUsers();

        setFinetuningUsers(users);
      }
      catch (error) {
        setFinetuningUsers([]);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const initialAssignedUsers = data.reduce((acc, review) => {
      acc[review.uuid] = review.assigned_users || [];

      return acc;
    }, {});

    setAssignedUsersMap(initialAssignedUsers);
  }, [data]);

  const handleAssignClick = reviewId => {
    setDropdownOpenRow(prev => (prev === reviewId ? null : reviewId));
  };

  const handleUserSelect = async (user, designReview) => {
    try {
      await FineTuningService.assignDesignReview(designReview.uuid, user.uuid);

      const updatedDesignReview = await FineTuningService.getDesignReviewById(designReview.uuid);

      setAssignedUsersMap(prev => ({ ...prev, [designReview.uuid]: updatedDesignReview?.assigned_users || [] }));
    }
    catch (error) {
      setDropdownOpenRow(null);
    }
    setDropdownOpenRow(null);
  };

  const columns = [
    {
      title: '',
      key: 'select',
      render: record => {
        const designReview = data.find(item => item.uuid === record.uuid) || record;

        return (
          <Checkbox
            checked={selectedDesigns.some(selected => selected.uuid === record.uuid)}
            onChange={() => toggleSelection(designReview)}
          />
        );
      },
      width: '3%',
    },
    {
      title: 'Preview',
      dataIndex: 'frame_image',
      key: 'frame_image',
      render: (frameImage, record) => frameImage
        ? (
          <img
            alt={`${record.review_frame_name} preview`}
            src={`${process.env.REACT_APP_FIREBASE_STORAGE_PREFIX}${encodeURIComponent(frameImage)}?alt=media`}
            className={styles['review-image']}
          />
        )
        : (
          <div className={styles['image-placeholder']} >
            <FileImageOutlined style={{ fontSize: '2rem', color: '#aaa' }} />
          </div>
        ),
      width: '18%',
    },
    {
      title: 'User Email',
      dataIndex: 'owner_email',
      key: 'owner_email',
      width: '14%',
    },
    {
      title: 'Frame Name',
      dataIndex: 'review_frame_name',
      key: 'review_frame_name',
      width: '8%',
    },
    {
      title: 'Template',
      dataIndex: 'template',
      key: 'template',
      width: '8%',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: date => (date ? moment(date).format('DD MMM YYYY, HH:mm A') : 'N/A'),
      width: '9%',
    },
    {
      title: 'Design Type',
      dataIndex: 'page_type',
      key: 'page_type',
      width: '9%',
    },
    {
      title: 'Labeled By',
      dataIndex: 'reviewers',
      key: 'reviewers',
      render: reviewers => reviewers.length > 0
        ? (
          <div className={styles['reviewer-avatars']} >
            {reviewers.map(reviewer => (
              <Tooltip key={reviewer.uuid} title={reviewer.full_name || 'Unknown'}>
                <Avatar
                  className={styles['avatar']}
                  src={reviewer.profile_photo || undefined}
                  icon={!reviewer.profile_photo ? <UserOutlined /> : undefined}
                  style={{ backgroundColor: reviewer.profile_photo ? 'transparent' : '#00b189' }}
                />
              </Tooltip>
            ))}
          </div>
        )
        : (
          <span className={styles['not-labeled']} >
            Not Labeled Yet
          </span>
        ),
      width: '10%',
    },
    {
      title: 'Assigned To',
      key: 'assigned_to',
      render: record => (
        <div className={styles['assigned-users-wrapper']}>
          {assignedUsersMap[record.uuid]?.map(user => (
            <Tooltip key={user.uuid} title={user.full_name || user.username}>
              <Avatar
                className={styles['avatar']}
                src={user.profile_photo || undefined}
                icon={!user.profile_photo ? <UserOutlined /> : undefined}
              />
            </Tooltip>
          ))}

          <Dropdown
            overlay={(
              <Menu className={styles['dropdown-menu']}>
                {finetuningUsers.map(user => (
                  <Menu.Item key={user.uuid} onClick={() => handleUserSelect(user, record)}>
                    <div className={styles['dropdown-item']}>
                      <Avatar src={user.profile_photo} className={styles['dropdown-avatar']} />
                      {user.full_name || user.username}
                    </div>
                  </Menu.Item>
                ))}
              </Menu>
            )}
            trigger={['click']}
            visible={dropdownOpenRow === record.uuid}
            onVisibleChange={visible => setDropdownOpenRow(visible ? record.uuid : null)}
          >
            <PlusCircleOutlined className={styles['assign-icon']} onClick={() => handleAssignClick(record.uuid)} />
          </Dropdown>
        </div>
      ),
      width: '12%',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: record => (
        <button
          type="primary"
          onClick={() => handleReviewClick(record)}
          className={styles['view-details-button']}
        >
          View Details
        </button>
      ),
      width: '9%',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={tableData}
      rowKey="uuid"
      pagination={false}
      tableLayout="fixed"
      className={styles['design-review-table']}
    />
  );
};

DesignReviewBox.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      extra_data: PropTypes.shape({
        frame_image: PropTypes.string,
        page_type: PropTypes.string,
        template: PropTypes.string,
        review_frame_name: PropTypes.string,
      }).isRequired,
      created_at: PropTypes.string,
      reviewers: PropTypes.array,
      assigned_users: PropTypes.array,
      owner: PropTypes.shape({
        email: PropTypes.string,
      }),
    })
  ).isRequired,
  selectedDesigns: PropTypes.array.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  handleReviewClick: PropTypes.func.isRequired,
};
