import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu as DropdownMenuOptions } from 'antd';
import cn from 'classnames';

import defaultUserAvatar from 'assets/user_avatar.svg';

import { DropdownMenu } from 'Components/DropdownMenu';

import { ROUTES } from 'routes/route.constants';

import { logoutUser, onLoadImageError } from 'utlis/globalFunctions';

import styles from './styles.module.scss';

export const AccountDropdown = ({ userProfile }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <DropdownMenu
      overlayClassName={styles['dropdown-wrapper']}
      trigger={['click']}
      overlay={
        <DropdownMenuOptions
          items={[
            {
              key: 'settings',
              label: (
                <Link className={styles['dropdown-option']} to={ROUTES.PROFILE_SETTINGS}>
                  Settings
                </Link>
              ),
            },
            {
              key: 'log_out',
              label: (
                <button
                  className={cn(styles['logout-option'], styles['dropdown-option'])}
                  onClick={() => logoutUser(dispatch, navigate)}
                >
                  Log Out
                </button>
              ),
            },
          ]}
        />
      }
    >
      <div className={cn('flex align-center pointer', styles['account-dropdown-trigger'])}>
        <img
          className={styles['user-avatar']}
          src={userProfile.profile_photo || defaultUserAvatar}
          onError={e => onLoadImageError(e, defaultUserAvatar)}
          alt="User Avatar"
        />
        <span className={cn('mx-8', styles['nav-item'])}>
          Account
        </span>
        <FontAwesomeIcon icon={faAngleDown} className={cn('ml-xs', styles['angle-down-icon'])} />
      </div>
    </DropdownMenu>
  );
};

AccountDropdown.propTypes = {
  userProfile: PropTypes.object.isRequired,
};
