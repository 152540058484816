import PropTypes from 'prop-types';
import { Skeleton, Table } from 'antd';

import styles from './styles.module.scss';

export const SkeletonCard = ({ rows = 5 }) => {
  const skeletonData = Array.from({ length: rows }, (_, index) => {
    void _;

    return { key: index };
  });

  const columns = [
    {
      title: '',
      key: 'select',
      width: '3%',
    },
    {
      title: 'Preview',
      key: 'frame_image',
      render: () => (
        <div className={styles['skeleton-card__image-wrapper']}>
          <Skeleton.Image className={styles['skeleton-card__image']} active />
        </div>
      ),
      width: '18%',
    },
    {
      title: 'User Email',
      key: 'owner_email',
      render: () => <Skeleton.Input active size="small" />,
      width: '14%',
    },
    {
      title: 'Frame Name',
      key: 'review_frame_name',
      render: () => <Skeleton.Input active size="small" />,
      width: '8%',
    },
    {
      title: 'Template',
      key: 'template',
      render: () => <Skeleton.Input active size="small" />,
      width: '8%',
    },
    {
      title: 'Created At',
      key: 'created_at',
      render: () => <Skeleton.Input active size="small" />,
      width: '9%',
    },
    {
      title: 'Design Type',
      key: 'page_type',
      render: () => <Skeleton.Input active size="small" />,
      width: '9%',
    },
    {
      title: 'Labeled By',
      key: 'reviewers',
      render: () => (
        <div className={styles['skeleton-card__reviewers']}>
          <Skeleton.Avatar active size="small" />
          <Skeleton.Avatar active size="small" />
        </div>
      ),
      width: '10%',
    },
    {
      title: 'Assigned To',
      key: 'assigned_to',
      render: () => (
        <div className={styles['skeleton-card__reviewers']}>
          <Skeleton.Avatar active size="small" />
          <Skeleton.Avatar active size="small" />
        </div>
      ),
      width: '12%',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: () => <Skeleton.Button active size="small" />,
      width: '9%',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={skeletonData}
      rowKey="key"
      pagination={false}
      tableLayout="fixed"
      className={styles['skeleton-card__table']}
    />
  );
};

SkeletonCard.propTypes = {
  rows: PropTypes.number,
};
