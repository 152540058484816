import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import './landing.scss';

import { identifyUser } from 'services/eventTracking';

import { InstallExtensionButton } from './components/InstallExtensionButton';
import { InstallPluginButton } from './components/InstallPluginButton';
import { LandingPageUI } from './components/LandingPageUI';

const Landing = () => {
  const userProfile = useSelector(state => state.userProfile);

  useEffect(() => {
    identifyUser(userProfile.uuid);
  }, [userProfile]);

  const installButton = (
    <InstallPluginButton />
  );

  const installButton2 = (
    <InstallExtensionButton />
  );

  return (
    <LandingPageUI installButton={installButton} InstallExtensionButton={installButton2} />
  );
};

export default Landing;
